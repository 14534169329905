import { Intent } from "@blueprintjs/core";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { HexButton, HexDialog } from "../../hex-components";
import { CyData } from "../../util/cypress";

export interface ConfirmationDialogProps {
  className?: string;
  title: string;
  confirmationText: string | ReactNode;
  confirmationButtonText?: string;
  confirmationButtonIntent?: Intent;
  confirmationDisabled?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCloseClick?: React.MouseEventHandler;
  onConfirm: () => void | Promise<void>;
  onConfirmClick?: React.MouseEventHandler;
  dataCy?: string | undefined;
}

const ConfirmationDialogBody = styled.div`
  padding: 20px 20px 0 20px;
`;
const ConfirmationTextDiv = styled.div`
  margin-bottom: 20px;
`;
const ButtonsDiv = styled.div`
  text-align: right;
`;

const ConfirmButton = styled(HexButton)`
  margin-left: 10px;
`;

export const ConfirmationDialog: React.ComponentType<ConfirmationDialogProps> =
  React.memo(function ConfirmationDialog({
    className,
    confirmationButtonIntent = Intent.PRIMARY,
    confirmationButtonText = "Confirm",
    confirmationDisabled = false,
    confirmationText,
    dataCy,
    isOpen,
    onClose,
    onCloseClick,
    onConfirm,
    onConfirmClick,
    title,
  }) {
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmButtonRef, setConfirmButtonRef] =
      useState<HTMLButtonElement | null>(null);

    const onClick = useCallback(
      async (e) => {
        onConfirmClick?.(e);
        setLoading(true);
        await onConfirm();
        setLoading(false);
      },
      [onConfirm, onConfirmClick],
    );

    const handleClose = useCallback(
      (e) => {
        onCloseClick?.(e);
        onClose();
      },
      [onClose, onCloseClick],
    );

    // auto-focus the confirmation button when the dialog opens
    useEffect(() => {
      if (isOpen && confirmButtonRef) {
        confirmButtonRef.focus();
      }
    }, [isOpen, confirmButtonRef]);

    return (
      <HexDialog
        className={className}
        isOpen={isOpen}
        title={title}
        onClose={handleClose}
      >
        <ConfirmationDialogBody data-cy={dataCy}>
          <ConfirmationTextDiv>{confirmationText}</ConfirmationTextDiv>
          <ButtonsDiv>
            <HexButton onClick={handleClose}>Cancel</HexButton>
            <ConfirmButton
              data-cy={CyData.CONFIRM_BUTTON}
              disabled={confirmationDisabled}
              ref={setConfirmButtonRef}
              intent={confirmationButtonIntent}
              loading={loading}
              onClick={onClick}
            >
              {confirmationButtonText}
            </ConfirmButton>
          </ButtonsDiv>
        </ConfirmationDialogBody>
      </HexDialog>
    );
  });
